<template>
  <div class="storage-status-footer">
    <div class="storage-status-label">
      {{ $t('cloud.storage-status') }}
    </div>
    <div class="storage-status-value">
      <div
        v-if="totalStorage <= 0"
        class="text-success font-weight-bold"
      >
        {{ $t('cloud.unlimited') }}
      </div>
      <div v-else>
        <div class="storage-status-progress-label">
          <span class="font-weight-bold">{{ (totalStorage - availableStorage).toFixed(0) }} MB</span>
          {{ $t('cloud.usage-info') }}
          <span class="font-weight-bold">{{ totalStorage }} MB</span>
        </div>
        <b-progress :max="totalStorage">
          <b-progress-bar
            :value="(totalStorage - availableStorage)"
            variant="secondary"
          />
        </b-progress>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BProgress, BProgressBar } from 'bootstrap-vue'

export default {
  name: 'StorageStatusFooter',
  components: {
    BProgress,
    BProgressBar,
  },
  data() {
    return {
      upgradeModal: false,
    }
  },
  computed: {
    ...mapGetters({
      availableStorage: 'auth/availableStorage',
      totalStorage: 'auth/totalStorage',
    }),
  },
}
</script>

<style lang="scss">
.storage-status-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .storage-status-label {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.3px;
  }

  .storage-status-value {
    font-size: 11px;
  }

  .storage-status-upgrade {
    font-size: 11px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    color: var(--okmenu-color);
  }

  .storage-status-progress-label {
    font-size: 8px;
    padding-bottom: 2px;
  }
}
</style>
