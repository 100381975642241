<template>
  <div>
    <validation-observer ref="form">

      <empty-card
        :title="$t('business.theme-title')"
      >
        <template slot="title">
          <div
            class="d-flex align-items-center"
          >
            <b-button
              variant="dark"
              :disabled="submitting"
              @click="submit()"
            >
              <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
              <b-spinner
                v-if="submitting"
                small
              />
            </b-button>
          </div>
        </template>
        <error-alert
          v-if="error"
          :error="error"
        />
        <theme-form
          ref="theme"
          @submit-review="submit(true)"
        />
      </empty-card>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ErrorHandler from '@/utils/error-handler'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import AuthApi from '@/api/auth'
import FormsService from '@/services/forms-service'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import ThemeForm from './components/ThemeForm'

export default {
  name: 'ProcessThemePage',
  components: {
    ErrorAlert,
    ThemeForm,
    EmptyCard,
    ValidationObserver,
    BButton,
    BSpinner,
  },
  data() {
    return {
      submitting: false,
      isAppearanceValid: true,
      error: null,
    }
  },
  methods: {
    async submit(forReview = false) {
      await new Promise(resolve => setTimeout(resolve, 200))
      const valid = await (this.$refs.form.validateWithInfo())
      this.error = null
      if (!valid.isValid) {
        this.isAppearanceValid = await this.$refs.theme.isValid()
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }

      // eslint-disable-next-line no-multi-assign
      this.isAppearanceValid = true
      const payload = this.$refs.theme.prepareForSubmit()
      const formData = FormsService.convertObjectToFormData(payload)
      this.submitting = true
      AuthApi.updateTheme(formData)
        .then(response => {
          this.$notifySuccess(response.message)
          this.$store.commit('auth/UPDATE_USER', { ...payload })
          if (forReview) {
            this.$refs.theme.reloadPreview()
          }
        })
        .catch(error => { this.error = error })
        .finally(() => { this.submitting = false })
    },
  },
}
</script>
