<template>
  <div
    ref="container"
    class="w-100 webapp-preview-container position-relative d-none d-lg-block"
    :class="{'need-refresh': !previewed}"
  >
    <b-button
      v-if="!previewed"
      class="preview-btn"
      variant="outline-light"
      :disabled="loading"
      @click="refresh"
    >
      <b-spinner
        v-if="loading"
        small
      />
      <span v-else>
        {{ $t('business.theme.save-preview') }}
      </span>
    </b-button>
    <div
      ref="webapp-container"
      class="webapp-preview"
    >
      <div class="preview-web-app-container">
        <iframe
          class="preview-web-app"
          allowfullscreen
          :src="url"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip, BSpinner, BButton } from 'bootstrap-vue'
import FormsService from '@/services/forms-service'

export default {
  name: 'WebappPreview',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BButton,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
    themeId: {
      type: [Number, String],
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    previewed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      time: Date.now(),
    }
  },
  computed: {
    url() {
      const query = FormsService.prepareQueryString({ _t: this.time }, true)
      return `${this.$store.getters['auth/user'].app_url}?${query}`
    },
  },
  watch: {
    url() {
      this.reload()
    },
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    reload() {
      this.updatingWidth = true
      this.time = Date.now()
      this.$nextTick(() => { this.updatingWidth = false })
    },
  },
}
</script>

<style lang="scss">
.webapp-preview-container {
  margin-left: 20px!important;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  border-radius: 15px!important;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 400px;

  .preview-btn {
    top: 50%;
    z-index: 2;
    position: absolute;
    left: 50%;
    min-width: 179px;
    transform: translate(-50%, 0);

    padding: 6px 25px!important;
    border-radius: 25px!important;
    border: solid 1px #fff!important;
    background-color: rgba(255, 255, 255, 0.16)!important;

    font-size: 16px!important;
    font-weight: 600!important;
    line-height: 1.94!important;
    color: #fff!important;

    &:hover {
      color: #fff!important;
    }
  }

  &.need-refresh {
    .webapp-preview {
      background-color: rgb(0, 0, 0);
      filter: brightness(46%);
    }
  }

  .webapp-preview {
    overflow: auto;

    .preview-web-app-container {

      .preview-web-app {
        max-width: 400px;
        width: 100%;
        min-height: 800px;
        max-height: 800px;
        border: 0;
        margin-bottom: -5px;
        -webkit-box-shadow: 0 0 15px #bbbbbb96;
        box-shadow: 0 0 15px #bbbbbb96;
      }
    }
  }

  .webapp-preview-link {
    margin-bottom: 10px;
  }
}

</style>
