<template>
  <b-modal
    :visible="value"
    :dialog-class="`mnassah-modal ${dialogClass}`"
    cancel-variant="secondary"
    centered
    :size="size"
    hide-footer
    hide-header
    hide-header-close
    :no-close-on-backdrop="noCloseOnBackdrop"
    lazy
    @change="$emit('change', $event)"
  >

    <div class="mnassah-modal-title">
      <span>{{ title }}</span>
      <b-button
        class="btn-icon"
        variant="link"
        @click="$emit('change', false)"
      >
        <feather-icon
          icon="XIcon"
          class="text-secondary"
          size="14"
        />
      </b-button>
    </div>

    <div class="mnassah-modal-content">
      <slot />
    </div>

    <div class="mnassah-modal-footer">
      <slot name="actions" />
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'

export default {
  name: 'MyMenuModal',
  components: {
    BModal, BButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    dialogClass: {
      type: String,
      default: '',
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
.mnassah-modal {
  .modal-body {
    padding-bottom: 20px;
  }

  .mnassah-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: solid 1px #e8e7eb;
    margin-bottom: 13.5px;

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #6d6a7a;
    }
  }

  .mnassah-modal-footer {
    margin-top: 15px;

    .btn {
      font-size: 14px!important;
      font-weight: 600!important;
      line-height: 1.29!important;
    }
  }

  .mnassah-modal-content {
    font-size: 12px;
    line-height: 1.25;
    font-weight: 500;
    color: #aaa8b2;
  }
}
</style>
