<template>
  <div class="mnassah-cloud-list">
    <div class="mnassah-cloud-list-title">
      {{ $t('cloud.okmenu-cloud') }}
      <feather-icon
        v-b-tooltip="$t('cloud.cloud-tooltip')"
        icon="HelpCircleIcon"
      />
    </div>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container mnassah-cloud-list-container row no-gutters w-100 scroll-area "
      tagname="div"
    >
      <b-col
        v-for="(file, idx) in files"
        :key="idx"
        cols="4"
      >
        <my-menu-cloud-list-item
          :file="file"
          :selected="!!value.find(f => f.id === file.id)"
          @click="handleSelectFile(file)"
        />
      </b-col>

      <b-button
        v-if="lastPage > page"
        variant="outline-secondary"
        block
        :disabled="fetching"
        @click="fetch"
      >
        <b-spinner
          v-if="fetching"
          small
        />
        <span v-else>{{ $t('general.see-more') }}</span>
      </b-button>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  VBTooltip, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import CloudAPIs from '@/api/cloud-api'
import MyMenuCloudListItem from './MyMenuCloudListItem'

export default {
  name: 'MyMenuCloudList',
  components: {
    MyMenuCloudListItem,
    BCol,
    BButton,
    BSpinner,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    onlyImages: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      page: 1,
      lastPage: 2,
      files: [],
      fetching: false,
      perfectScrollbarSettings: {
        wheelPropagation: true,
      },
    }
  },
  watch: {
    onlyImages(onlyImages) {
      if (!onlyImages) return
      this.page = 1
      this.lastPage = 2
      this.files = []

      this.fetch()
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.fetching = true

      CloudAPIs.index(this.page, 6, this.onlyImages ? 'image' : null)
        .then(response => {
          this.lastPage = response.pagination.last_page
          this.files = [...this.files, ...response.data]
          this.page += 1
        })
        .catch(e => this.$notifyError(e))
        .finally(() => { this.fetching = false })
    },
    handleSelectFile(file) {
      if (this.value.length < this.maxFiles) {
        if (this.value.find(f => f.id === file.id)) {
          this.$emit('input', this.value.filter(f => f.id !== file.id))
        } else {
          this.$emit('input', this.multiple ? [...this.value, file] : [file])
        }
      } else {
        this.$notifyError(`يمكنك اختيار ${this.maxFiles} على الاكثر`)
      }
    },
  },

}
</script>

<style lang="scss">
.mnassah-cloud-list {
  width: 100%;

  .mnassah-cloud-list-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .mnassah-cloud-list-container {
    width: 100%;
    display: flex;
    max-height: 200px;
    position: relative;
    overflow-y: auto;

    .col-4 {
      margin-bottom: 5px;
      padding: 0 5px;
    }
  }
}
</style>
