class VideoThumbnailService {
    static scaleFactor = 0.25;

    static capture(video: any) {
      try {
        const w = video.videoWidth * (VideoThumbnailService?.scaleFactor ?? 1)
        const h = video.videoHeight * (VideoThumbnailService?.scaleFactor ?? 1)
        const canvas = document.createElement('canvas')
        canvas.width = w
        canvas.height = h
        const ctx: any = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, w, h)
        const dataURI = canvas.toDataURL('image/jpg')
        const file: any = VideoThumbnailService.dataURLtoBlob(dataURI, 'image/jpg', `${+new Date()}.jpg`)

        return {
          canvas,
          file,
          base64: dataURI,
        }
      } catch (e) {
        console.error(e)
      }

      return {}
    }

    static dataURLtoBlob(dataUrl: string, type?: string, name?: string): Blob | null {
      try {
        const arr = dataUrl.split(',')
        const mime = type ?? arr[0].match(/:(.*?);/)?.[1] ?? ''
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)

        // eslint-disable-next-line no-plusplus
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        const blob = new Blob([u8arr], { type: mime })
        return new File([blob], name || 'thumbnail.jpg')
      } catch (e) {
        console.error(e)
        return null
      }
    }

    static async generateThumbnails(element: any) {
      if (!element?.duration) return []
      const thumbnails = []
      const el = element
      const { duration } = el
      const chunk = duration / 3
      let prevTime = 0

      for (let i = 1; i <= 3; i += 1) {
        el.currentTime = prevTime + chunk / 2
        // eslint-disable-next-line no-await-in-loop
        await (new Promise(resolve => { el.addEventListener('timeupdate', function handler() { el.removeEventListener('timeupdate', handler); resolve(true) }) }))
        thumbnails.push({ ...VideoThumbnailService.capture(el), time: el.currentTime })
        prevTime += chunk
      }

      el.currentTime = 0
      return thumbnails
    }
}

export default VideoThumbnailService
