// @ts-ignore
import Compressor from 'compressorjs'
// @ts-ignore
import ImageBlobReduce from 'image-blob-reduce'

class MediaService {
  static imageReducer = false

  private static getImageReducer() {
    if (MediaService.imageReducer) return MediaService.imageReducer

    const imageReducer = new ImageBlobReduce()
    // eslint-disable-next-line no-underscore-dangle
    imageReducer._create_blob = function (env: any) {
      return this.pica.toBlob(env.out_canvas, 'image/png', 0.99).then((blob: Blob) => {
        // eslint-disable-next-line no-param-reassign
        env.out_blob = blob
        return env
      })
    }
    MediaService.imageReducer = imageReducer
    return imageReducer
  }

  static async reduceBlobSize(fileBlob: any, max = 1200, quality = 1, compress = false) {
    return this.getImageReducer().toBlob(fileBlob, { max, compress, quality })
  }

  static async compressImage(file: any, quality = 0.8, maxWidth = 1200) {
    return new Promise((resolve, reject) => {
      if (!file?.type?.includes('image/')) {
        resolve(file)
      }
      return new Compressor(file, {
        quality,
        maxWidth,
        success(result: any) {
          resolve(result)
        },
        error(err: any) {
          console.error(err)
          reject(err)
        },
      })
    })
  }

  static FileToDataURL(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  static dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ab], { type: mimeString })
  }

  static BlobToFile(blob: Blob, filename: string) {
    return new File([blob], filename, { type: blob.type })
  }

  static dataURItoFile(dataURI: string, filename: string) {
    const blob = MediaService.dataURItoBlob(dataURI)
    return MediaService.BlobToFile(blob, filename)
  }
}

export default MediaService
