export function dropzoneDefaultTemplate() {
  return `
    <div class="dz-preview dz-file-preview">
      <div class="dz-image"><img data-dz-thumbnail /></div>
      <div class="dz-details">
        <div class="dz-size"><span data-dz-size></span></div>
        <div class="dz-filename"><span data-dz-name></span></div>
      </div>
      <div class="dz-progress">
        <span class="dz-upload" data-dz-uploadprogress></span>
      </div>
      <div class="dz-error-message"><span data-dz-errormessage></span></div>
      <div class="dz-success-mark">
        <svg
          width="54"
          height="54"
          viewBox="0 0 54 54"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2071 29.7929L14.2929 25.7071C14.6834 25.3166 15.3166 25.3166 15.7071 25.7071L21.2929 31.2929C21.6834 31.6834 22.3166 31.6834 22.7071 31.2929L38.2929 15.7071C38.6834 15.3166 39.3166 15.3166 39.7071 15.7071L43.7929 19.7929C44.1834 20.1834 44.1834 20.8166 43.7929 21.2071L22.7071 42.2929C22.3166 42.6834 21.6834 42.6834 21.2929 42.2929L10.2071 31.2071C9.81658 30.8166 9.81658 30.1834 10.2071 29.7929Z"
          />
        </svg>
      </div>
      <div class="dz-error-mark">
        <svg
          width="54"
          height="54"
          viewBox="0 0 54 54"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.2929 20.2929L19.2071 13.2071C18.8166 12.8166 18.1834 12.8166 17.7929 13.2071L13.2071 17.7929C12.8166 18.1834 12.8166 18.8166 13.2071 19.2071L20.2929 26.2929C20.6834 26.6834 20.6834 27.3166 20.2929 27.7071L13.2071 34.7929C12.8166 35.1834 12.8166 35.8166 13.2071 36.2071L17.7929 40.7929C18.1834 41.1834 18.8166 41.1834 19.2071 40.7929L26.2929 33.7071C26.6834 33.3166 27.3166 33.3166 27.7071 33.7071L34.7929 40.7929C35.1834 41.1834 35.8166 41.1834 36.2071 40.7929L40.7929 36.2071C41.1834 35.8166 41.1834 35.1834 40.7929 34.7929L33.7071 27.7071C33.3166 27.3166 33.3166 26.6834 33.7071 26.2929L40.7929 19.2071C41.1834 18.8166 41.1834 18.1834 40.7929 17.7929L36.2071 13.2071C35.8166 12.8166 35.1834 12.8166 34.7929 13.2071L27.7071 20.2929C27.3166 20.6834 26.6834 20.6834 26.2929 20.2929Z"
          />
        </svg>
      </div>
    </div>
`
}
export function dropzoneBasicTemplate(withProgress = true) {
  return `
  <div class="dz-basic dz-preview dz-file-preview">
      <div class="dz-image-details-container">
          <div class="dz-basic-image"><img data-dz-thumbnail /></div>
          <div class="dz-details">
            <div class="dz-filename"><span data-dz-name></span></div>
            <a href="#" class="dz-remove text-danger cursor-pointer" data-dz-remove> <i class="feather icon-trash-2"></i> </a>
          </div>
          ${withProgress ? '<div class="dz-progress"> <span class="dz-upload" data-dz-uploadprogress></span> </div>' : ''}
    </div>
  <div class="dz-error-message"><span data-dz-errormessage></span></div>
  <div class="dz-success-mark">
    <svg
      width="54px"
      height="54px"
      viewBox="0 0 54 54"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Check</title>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"
          stroke-opacity="0.198794158"
          stroke="#747474"
          fill-opacity="0.816519475"
          fill="#FFFFFF"
        ></path>
      </g>
    </svg>
  </div>
  <div class="dz-error-mark">
    <svg
      width="54px"
      height="54px"
      viewBox="0 0 54 54"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Error</title>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          stroke="#747474"
          stroke-opacity="0.198794158"
          fill="#FFFFFF"
          fill-opacity="0.816519475"
        >
          <path
            d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"
          ></path>
        </g>
      </g>
    </svg>
  </div>
</div>`
}

export function dropzoneCoverTemplate(withProgress = false) {
  return `
      <div class="dz-preview dz-preview-cover dz-file-preview">
        <div class="h-100">
          <div class="p-0 position-relative h-100">
              <div class="dz-error-mark"><span><i></i>  </span></div>
              <div class="dz-success-mark"><span><i></i></span></div>
              <div class="preview-container">
                <img data-dz-thumbnail class="img-thumbnail border-0" />
                <i class="simple-icon-doc preview-icon"></i>
              ${withProgress ? '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' : ''}
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
              </div>
          </div>
          <a href="#" class="remove cursor-pointer text-white" title="Remove" data-dz-remove> <i class="feather icon-trash-2"></i> </a>
      </div>`
}

export function dropzoneMyMenuTemplate(withProgress = true) {
  return `
      <div class="dz-preview dz-file-preview-mnassah dz-file-preview">
        <div class="h-100">
          <div class="p-0 position-relative h-100">
              <div class="dz-error-mark"><span><i></i>  </span></div>
              <div class="dz-success-mark"><span><i></i></span></div>
              <div class="preview-container">
                <div>
                  <img data-dz-thumbnail class="img-thumbnail border-0" />
                </div>
                <div class="dz-file-details">
                  <div class="dz-filename"><span data-dz-name></span></div>
                  <div class="dz-size" data-dz-size></div>
                  <div class="dz-error-message-custom text-danger"><span></span></div>
                </div>
              ${withProgress ? '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' : ''}
              </div>
              <a href="#" class="remove cursor-pointer text-white" data-dz-remove> <i class="feather icon-trash-2"></i> </a>
          </div>
      </div>`
}
