<template>
  <my-menu-modal
    :value="value"
    size="xs"
    dialog-class="upload-media-modal"
    :title="$t('cloud.media')"
    :no-close-on-backdrop="false"
    @change="loading ? null : $emit('input', $event)"
  >
    <preview-uploaded-video
      v-if="previewVideo"
      v-model="previewVideo"
      @cancel="previewVideo = null"
      @save="handleSaveVideo"
    />

    <add-video-link
      v-if="showAddVideoUrl"
      @cancel="showAddVideoUrl = false"
      @save="handleSetVideoUrl"
    />

    <div v-show="!previewVideo && !showAddVideoUrl">
      <input
        id="upload-media-input"
        type="file"
        hidden
        :multiple="multiple"
        :accept="'image/jpg, image/png, image/jpeg' + (onlyImages ? '' : `, video/mp4, video/mov, video/mpg, video/mpeg, video/mkv`)"
        @change="handleFileUploaded"
      >

      <b-button
        block
        variant="dark"
        class="upload-media-modal-action-button"
        @click="handleUploadFile"
      >
        {{ onlyImages ? $t('cloud.upload-image') : $t('cloud.upload-image-video') }}
      </b-button>

      <div
        v-if="error"
        class="extension-error text-danger mb-1"
      >
        {{ error }}
      </div>

      <b-button
        v-if="!onlyImages"
        block
        variant="outline-dark"
        class="upload-media-modal-action-button"
        @click="showAddVideoUrl = true"
      >
        {{ $t('cloud.add-youtube-link') }}
      </b-button>

      <div
        v-if="value"
        class="cloud-list"
      >
        <my-menu-cloud-list
          v-model="selectedFiles"
          :only-images="onlyImages"
          :multiple="multiple"
          :max-files="maxFiles"
        />
      </div>
    </div>

    <template #actions>
      <storage-status-footer />
    </template>
  </my-menu-modal>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import sortBy from 'lodash/sortBy'
import videoExtensions, { videoMimes } from '@/constants/media/video-extensions'
import MyMenuModal from './MyMenuModal'
import MyMenuCloudList from '../MyMenuCloudList'
import PreviewUploadedVideo from '../PreviewUploadedVideo'
import AddVideoLink from '../AddVideoLink'
import StorageStatusFooter from '../StorageStatusFooter'

export default {
  name: 'UploadMediaModal',
  components: {
    StorageStatusFooter,
    AddVideoLink,
    PreviewUploadedVideo,
    MyMenuCloudList,
    MyMenuModal,
    BButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    onlyImages: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default: () => ([]),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      loading: false,
      previewVideo: null,
      showAddVideoUrl: false,
      error: '',
    }
  },
  computed: {
    selectedFiles: {
      get() { return this.files },
      set(val) { this.$emit('update:files', val) },
    },
  },
  watch: {
    value(value) {
      if (!value) {
        this.previewVideo = null
        this.showAddVideoUrl = false
        this.error = null
      }
    },
  },
  methods: {
    handleUploadFile() {
      document.getElementById('upload-media-input').click()
    },
    handleFileUploaded(event) {
      const files = sortBy(event?.target?.files ?? [], 'type')
      let hasVideo = false
      this.error = null

      files.forEach(file => {
        if (!file) return
        const isImage = file.type.includes('image')
        const isVideo = videoMimes.includes(file.type)

        if (!isImage && !isVideo) {
          this.error = `${this.$t('cloud.allowed-formats')}: ${videoExtensions.join(', ').toUpperCase()}`
          return
        }

        if ((file.size / 1e6) > 100) {
          this.error = this.$t('cloud.allowed-video-size', [100])
        } else if (isImage) {
          this.$emit('upload', file)
        } else if (this.onlyImages) {
          this.error = this.$t('cloud.no-video-allowed')
        } else if (hasVideo) {
          this.error = this.$t('cloud.no-multiple-video')
        } else {
          hasVideo = true
          this.previewVideo = file
        }
      })

      if (!hasVideo && !this.error) {
        this.$emit('input', false)
      }
    },
    handleSaveVideo(thumbnail) {
      this.previewVideo.thumbnail = thumbnail?.file
      this.$emit('upload', this.previewVideo)
      this.previewVideo = null
      this.$emit('input', false)
    },
    handleSetVideoUrl(videoUrl) {
      this.$emit('url', videoUrl)
      this.showAddVideoUrl = false
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss">
.upload-media-modal {
  .upload-media-modal-action-button {
    margin-bottom: 10px;
  }

  .extension-error {
    font-size: 10px;
  }
}
</style>
