import axios from 'axios'
import $http from '@/libs/axios'
import FormsService from '@/services/forms-service'

class CloudAPIs {
  static cancelTokenSource: any = null

  static async index(page = 1, per_page = 6, media = '', general = null, otherQueries = {}) {
    try {
      if (CloudAPIs.cancelTokenSource !== null) {
        CloudAPIs.cancelTokenSource.cancel()
      }
      CloudAPIs.cancelTokenSource = axios.CancelToken.source()
      const query = FormsService.prepareQueryString({
        page, per_page, media, general, ...otherQueries,
      }, true)

      const response = (await $http.get(`cloud/images?${query}`, { cancelToken: CloudAPIs.cancelTokenSource.token })).data
      CloudAPIs.cancelTokenSource = null
      return response
    } catch (e) {
      if (!axios.isCancel(e)) {
        throw e
      }
    }
    return { data: [] }
  }

  static async uploadFile(body: any, uploadProgressHandler?: any) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const onUploadProgress = (progressEvent: any) => {
      if (uploadProgressHandler) {
        uploadProgressHandler(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      }
    }
    const config = { headers, onUploadProgress }
    return (await $http.post('cloud/add', body, config)).data
  }

  static async deleteFiles(ids: string) {
    return (await $http.post('cloud/delete', { _method: 'delete', ids })).data
  }
}

export default CloudAPIs
