<template>
  <div class="mnassah-media-input">
    <b-row class="no-gutters justify-content-center">
      <b-col
        v-for="(image, imageIdx) in value"
        :key="getImageUrl(image)"
        :lg="single?8:3"
        md="6"
        sm="6"
        class="mb-1"
      >
        <div class="mnassah-media-item h-100">
          <div
            v-if="image.youtube"
            class="text-danger h-100 w-100 d-flex justify-content-center align-items-center border-danger px-1 flex-column overflow-hidden cursor-pointer"
            @click="openUrl(image.url)"
          >
            <div>
              <feather-icon
                icon="YoutubeIcon"
                size="36"
              />
            </div>
            <div
              v-b-tooltip="image.url"
              class="one-line w-100 mt-1"
            >
              {{ image.url }}
            </div>
          </div>
          <ok-video-player
            v-else-if="isVideo(image)"
            :src="getImageUrl(image)"
            compact
            :poster="image.video_thumbnail || image.thumbnail"
            class="img-fluid"
          />
          <img
            v-else
            v-lazy="getImageUrl(image)"
            :src="getImageUrl(image)"
            class="img-fluid media-image"
            height="150"
            alt="image"
          >
          <b-button
            class="btn-icon remove-btn"
            size="sm"
            variant="danger"
            @click="removeImage(imageIdx)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </b-col>
      <b-col
        v-show="value.length<maxFiles "
        :lg="value.length ? 3 : 12"
        :md="value.length ? 6 : 12"
        :sm="value.length ? 6 : 12"
        class="mb-1"
      >
        <my-menu-dropzone-input
          ref="dropzone"
          :inline-template="!!value.length"
          :only-images="onlyImages"
          :max-files="single ? 1 : maxFiles"
          @upload="handleUpload"
          @click="showUploadModal = true"
        />
      </b-col>
    </b-row>
    <upload-media-modal
      v-model="showUploadModal"
      :files.sync="files"
      :only-images="onlyImages"
      :max-files="maxFiles"
      :multiple="!single"
      @upload="handleFileToUpload"
      @url="handleVideoUrl"
      @update:files="$emit('select-file',$event)"
    />
  </div>
</template>

<script>
import {
  BCol, BRow, BButton, VBTooltip,
} from 'bootstrap-vue'
import OkVideoPlayer from '@/@bya3/components/media/OkVidePlayer'
import videoExtensions from '@/constants/media/video-extensions'
import MyMenuDropzoneInput from './MyMenuDropzoneInput'
import UploadMediaModal from './modals/UploadMediaModal'

export default {
  name: 'MyMenuMediaInput',
  components: {
    OkVideoPlayer,
    UploadMediaModal,
    MyMenuDropzoneInput,
    BCol,
    BRow,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    single: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 10,
    },
    disableVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showUploadModal: false,
      removeVideo: false,
    }
  },
  computed: {
    files: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
    onlyImages() {
      if (this.disableVideo) return true

      const videoRegex = videoExtensions.map(ext => `.*\\.${ext}`)
      return this.files.some(file => {
        const url = this.getImageUrl(file)
        return file.youtube ?? new RegExp(videoRegex.join('|')).test(url)
      })
    },
  },
  methods: {
    isVideo(file) {
      if (file?.type === 'video') return true

      const url = this.getImageUrl(file)
      const videoRegex = videoExtensions.map(ext => `.*\\.${ext}`)
      return file.youtube || new RegExp(videoRegex.join('|')).test(url)
    },
    handleUpload(file) {
      this.$emit('input', [...this.value, file])
      this.$emit('uploaded', file)
    },
    getImageUrl(file) {
      return file?.original?.url ?? file?.url ?? ''
    },
    removeImage(imageIdx) {
      if (this.isVideo(this.files[imageIdx])) {
        this.removeVideo = true
      }
      this.files.splice(imageIdx, 1)
    },
    handleFileToUpload(file) {
      this.$refs.dropzone.addFileManually(file)
    },
    handleVideoUrl(url) {
      this.$emit('input', [...this.value, { url, youtube: true }])
    },
    openUrl(url) {
      window.open(url, '_blank')
    },
    prepareForSubmit() {
      const images = this.files.filter(file => !this.isVideo(file) && !file.youtube && file.id).map(file => file.id)

      if (this.disableVideo) {
        return { images }
      }

      if (this.files.some(file => this.isVideo(file))) this.removeVideo = false
      const youtubeLink = this.files.find(file => file.youtube)?.[0]?.url ?? null
      const videoId = this.files.filter(file => this.isVideo(file))?.[0]?.id ?? null

      return {
        video_id: videoId,
        remove_video: this.removeVideo,
        video: youtubeLink,
        images,
      }
    },
  },
}
</script>

<style lang="scss">
.mnassah-media-input {
  margin: 0 -7px;
  .mnassah-media-input-title {
    margin-bottom: 10px;
    padding: 0 10px;
    font-size: 14px!important;
    font-weight: 500;
    color: var(--purple);
  }

  .mnassah-media-item {
    position: relative;
    padding: 0 7px 10px!important;

    img {
      object-fit: cover;
      height: 150px!important;
      width: 100%;
      border-radius: 5px;
      background-color: #f0eff5 !important;
    }

    & > div {
      border-radius: 5px;
    }

    .remove-btn {
      position: absolute;
      top: 0;
      right: 7px;
    }
  }

  .mnassah-media-item .plyr {
    min-width: 100%;
    height: 100%;

    .plyr__controls {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
}
</style>
