<template>
  <div
    class="mnassah-cloud-list-item"
    :class="{'item-selected': selected}"
    @click="brokenImage ? null : $emit('click')"
  >

    <feather-icon
      v-if="selected"
      icon="CheckCircleIcon"
      class="selected-icon text-white cursor-pointer"
      size="26"
    />

    <b-spinner
      v-if="loading"
      small
    />

    <div v-if="brokenImage">
      <image-broken-icon
        size="60"
      />
    </div>
    <div
      v-else
      v-show="!loading"
      class="w-100 cursor-pointer mnassah-cloud-list-item-media"
    >
      <div
        v-if="isVideo"
        class="h-100 position-relative"
      >
        <ok-video-player
          :src="url"
          :compact="true"
          :disable-click-on-play="true"
          :poster="file.video_thumbnail"
        />
        <feather-icon
          icon="PlayCircleIcon"
          class="position-absolute center text-white play-icon"
          size="18"
        />
      </div>
      <img
        v-else
        :src="url"
        class="card-img-top cursor-pointer image-cover"
        height="250"
        :alt="`Broken Image`"
        @error="brokenImage = true;loading = false"
        @load="loading = false"
      >
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import OkVideoPlayer from '@/@bya3/components/media/OkVidePlayer'
import ImageBrokenIcon from '@/@bya3/icons/ImageBrokenIcon'

export default {
  name: 'MyMenuCloudListItem',
  components: {
    ImageBrokenIcon,
    OkVideoPlayer,
    BSpinner,
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      brokenImage: false,
      loading: false,
    }
  },
  computed: {
    url() {
      const { file } = this
      const url = file?.original?.url ?? file?.url ?? file
      const isImage = url && url.toLowerCase().match(/.*.(jpg|png|gif)$/i)
      return url && isImage ? `${url}?w=500` : url
    },
    isVideo() {
      const { file } = this
      const url = file?.original?.url ?? file?.url ?? file
      if (!url) return false
      return url.toLowerCase().match(/.*.(mp4|wmv|mpeg|avi)$/i)
    },
  },
  mounted() {
    if (!this.isVideo) {
      this.loading = true
    }
  },
}
</script>

<style lang="scss">
.mnassah-cloud-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 75px;
  height: 100%;

  img {
    height: 75px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  &.item-selected {
    .selected-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      width: 30px;
      height: 30px;
      z-index: 1;
    }

    .mnassah-cloud-list-item-media {
      filter: brightness(50%) blur(1px);
    }
  }

  .mnassah-cloud-list-item-media {
    height: 100%;

    .plyr {
      min-width: 100%;
      height: 100%;
      filter: brightness(80%);

      .plyr__controls__item {
        display: none;
      }
    }

    .play-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      width: 34px;
      height: 34px;
      z-index: 1;
    }
  }
}
</style>
