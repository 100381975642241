<template>
  <div />
</template>

<script>

export default {
  name: 'OkVideoPlayer',
  props: {
    videoId: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      default: null,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    disableClickOnPlay: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitDataLoaded() {
      this.$emit('data-loaded')
    },
  },
}
</script>
