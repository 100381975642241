const videoExtensions = [
  'mp4',
  'mov',
  'mpg',
  'mpeg',
  'mkv',
]

export default videoExtensions

export const videoMimes = [
  'video/mp4', 'video/mov', 'video/mpg', 'video/mpeg', 'video/mkv',
]
