<template>
  <div
    class="ms-logo"
    :class="{'d-lg-flex align-items-end': !small}"
  >
    <div
      class="logo-file-input custom-input position-relative"
      :class="{'is-invalid': state === false, 'small': small}"
    >
      <div
        v-ripple.400="'rgba(236, 174, 33, 0.15)'"
        class="cursor-pointer w-100 h-100 d-flex align-items-center justify-content-center"
        @click="showCropper = true"
      >
        <img
          v-if="preview && !imageFailed"
          :src="preview"
          class="image-contain"
          :height="small ? '100px' : '130px'"
          alt="Image"
          @error="imageFailed = true"
        >
        <div
          v-else
          class="text-center"
        >
          <b-spinner
            v-if="generatingBlob"
            label="Generating Blob..."
          />
          <div v-else-if="imageFailed">
            <feather-icon
              size="24"
              icon="AlertTriangleIcon"
            />
            <div>{{ $t('errors.image-load-failed') }}</div>
          </div>
          <feather-icon
            v-else
            size="29"
            icon="PlusSquareIcon"
          />
        </div>
      </div>
    </div>
    <avatar-cropper
      ref="avatar"
      v-model="showCropper"
      class="logo-cropper"
      :labels="avatarOptions.labels"
      :output-quality="outputQuality"
      :cropper-options="cropperOptions"
      :trigger="`#pick-logo`"
      @submit="handleSubmitImage"
    />
    <div
      v-if="!hideActions && value"
      class="logo-file-input-actions rounded"
      :class="{'d-flex': small}"
    >
      <b-button
        variant="outline-muted"
        class="text-nowrap"
        :class="{'d-block border-muted w-100 logo-file-input-update-btn': !small}"
        :size="small ? 'sm' : 'md'"
        @click.capture="showCropper = true"
      >
        <feather-icon icon="RefreshCwIcon" /> <span v-if="!small">{{ $t('business.update-logo') }}</span>
      </b-button>

      <b-button
        id="logo-delete"
        :variant="small ? 'outline-muted' : 'outline-danger'"
        class="text-nowrap "
        :class="{'d-block w-100 logo-file-input-delete-btn': !small}"
        :size="small ? 'sm' : 'md'"
        @click="handleRemoveImage"
      >
        <feather-icon icon="Trash2Icon" /> <span v-if="!small">{{ $t('business.delete-logo') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BSpinner, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MediaService from '@/services/media-service'

export default {
  name: 'LogoFileInput',
  components: {
    BSpinner,
    BButton,
    AvatarCropper: () => import(/* webpackChunkName: "vue-cropper" */ 'vue-avatar-cropper'),
  },
  directives: {
    Ripple,
  },
  props: {
    outputQuality: {
      type: Number,
      default: 0.9,
    },
    value: {
      type: [Blob, String],
      required: false,
      default: null,
    },
    hideActions: {
      type: Boolean,
      required: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preview: '',
      showCropper: false,
      generatingBlob: false,
      hover: false,
      imageFailed: false,
      avatarOptions: {
        labels: {
          submit: this.$t('forms.submit'),
          cancel: this.$t('forms.cancel'),
        },
      },
      cropperOptions: {
        aspectRatio: 1,
        autoCropArea: 1,
        viewMode: 0,
        movable: true,
        zoomable: true,
      },
    }
  },
  watch: {
    value(image) {
      if (typeof image === 'string') {
        this.preview = image
      }
    },
  },
  mounted() {
    if (typeof this.value === 'string') {
      this.preview = this.value
    }
  },
  methods: {
    handleSubmitImage() {
      const croppedCanvas = this.$refs.avatar.cropper.getCroppedCanvas()
      if (!croppedCanvas) {
        this.$notifyError('Can not get cropped image.')
        return
      }

      this.generatingBlob = true
      croppedCanvas.toBlob(async blob => {
        let compressedBlob = blob
        try {
          compressedBlob = await MediaService.reduceBlobSize(blob, 800)
        } catch (error) {
          console.error('failed to compress logo', error)
        }
        this.$emit('input', compressedBlob)
        this.imageFailed = false
        this.preview = croppedCanvas.toDataURL('image/png')
        this.generatingBlob = false
      })
    },
    handleRemoveImage() {
      this.$okConfirm(this.$t('forms.do-you-want-to-delete', [this.$t('business.logo')]), {
        preConfirm: () => {
          this.preview = null
          this.imageFailed = false
          this.$emit('input', null)
          this.$emit('removed', true)
        },
      })
    },
    getPreview() {
      return this.preview
    },
  },
}
</script>

<style lang="scss">
.logo-file-input-confirm {
  margin-left: -75px!important;
}

/* rtl:ignore */
[dir=rtl] .cropper-container {
  direction: ltr!important;
}
.ms-logo{
  border: 1px dashed;
  padding: 10px;
  width: 200px;
  height: 150px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
</style>
