<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <b-row class="item-general-form mt-2 align-items-center">
      <b-col md="8">
        <hr
          :data-content="$t('business.theme.colors-images')"
          class="hr-text"
        >
        <b-row class="mt-3">
          <b-col
            md="6"
            class="mb-1"
          >
            <div class="d-flex justify-content-between mb-1 pl-1">
              <div>
                {{ $t('business.theme.allow-order') }}
                <ok-tooltip :text="$t('business.theme.allow-order-tooltip')" />
              </div>
              <b-form-radio-group
                v-model="form.allow_order"
                :options="[{text:$t('general.yes'),value:1},{text:$t('general.no'),value:0}]"
              />
            </div>
          </b-col>
          <b-col md="6" />
          <b-col
            md="6"
            class="mb-1"
          >
            <div class="d-flex justify-content-between mb-1 pl-1">
              <div>
                {{ $t('business.theme.show-prices') }}
              </div>
              <b-form-radio-group
                v-model="form.show_prices"
                :options="[{text:$t('general.yes'),value:1},{text:$t('general.no'),value:0}]"
              />
            </div>
          </b-col>
          <b-col md="6" />
        </b-row>
        <hr
          :data-content="$t('business.theme.colors-images')"
          class="hr-text"
        >
        <b-row>
          <b-col
            md="4"
            class="mb-1"
          >
            <ok-text-input
              size="md"
              read-only
              :label="$t('business.theme.primary-color')"
              is-required
            >
              <ok-color-picker v-model="form.primary_color" />
            </ok-text-input>
          </b-col>
          <b-col
            md="8"
            class="mb-1 d-none d-md-block"
          />
          <b-col
            md="6"
          >
            <b-form-group
              :label="$t('business.logo')"
              label-class="font-weight-normal text-normal"
            >
              <logo-file-input
                ref="logo-input"
                v-model="form.logo"
                hide-actions
                small
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <hr>
            <b-form-group
              :label="$t('business.header-images')"
              label-class="font-weight-normal text-normal"
            >
              <my-menu-media-input
                ref="media"
                v-model="form.header_images"
                disable-video
              />
            </b-form-group>

          </b-col>
        </b-row>
        <hr
          data-content="Aspect ratio"
          class="hr-text"
        >
        <b-row class="mt-3">
          <b-col
            md="6"
            class="mb-1"
          >
            <div class="d-flex justify-content-between mb-1">
              <div>
                {{ $t('business.theme.respect-auto-fill') }}
                <ok-tooltip
                  :text="$t('business.theme.respect-ratio-tooltip')"
                  :placement="isRTL ? 'left' : 'right'"
                />
              </div>
              <ok-switch
                v-model="form.aspect_ratio"
                @input="form.aspect_ratio_color = $event ? form.aspect_ratio_color : ''"
              />
            </div>
            <div
              v-if="form.aspect_ratio"
              class="mt-2"
            >
              <b-row class="d-flex justify-content-between align-items-center mb-1">
                <b-col
                  sm="6"
                  class="text-nowrap"
                >
                  {{ $t('business.theme.image-background') }}
                  <ok-tooltip :text="$t('business.theme.image-background-tooltip')" />
                </b-col>
                <b-col sm="6">
                  <ok-color-picker v-model="form.aspect_ratio_color" />
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col md="6" />
        </b-row>
        <hr
          :data-content="$t('business.theme.items-layout')"
          class="hr-text"
        >
        <b-row class="mt-3">
          <b-col
            md="12"
            class="mb-1"
          >
            <div class="d-flex mb-1 pl-1">
              <div>
                {{ $t('business.theme.items-layout') }}
                <ok-tooltip :text="$t('business.theme.items-layout-tooltip')" />
              </div>
              <b-form-radio-group
                v-model="form.cate_wise"
                class="ml-5"
                :options="[{text:$t('business.theme.cats-items'),value:1},{text:$t('business.theme.only-items'),value:0}]"
              />
            </div>
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <div class="d-flex justify-content-between mb-1 pl-1">
              <div>
                {{ $t('business.theme.show-cat-images') }}
              </div>
              <b-form-radio-group
                v-model="form.show_cat_image"
                :options="[{text:$t('general.yes'),value:1},{text:$t('general.no'),value:0}]"
              />
            </div>
          </b-col>
          <b-col md="6" />
          <b-col
            md="6"
            class="mb-1"
          >
            <div class="d-flex justify-content-between mb-1 pl-1">
              <div>
                {{ $t('business.theme.show-item-images') }}
              </div>
              <b-form-radio-group
                v-model="form.show_item_image"
                :options="[{text:$t('general.yes'),value:1},{text:$t('general.no'),value:0}]"
              />
            </div>
          </b-col>
          <b-col md="6" />
        </b-row>
        <hr
          :data-content="$t('business.theme.rating')"
          class="hr-text"
        >
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <div class="d-flex justify-content-between mb-1 pl-1">
              <div>
                {{ $t('business.theme.rating-permit') }}
              </div>
              <b-form-radio-group
                v-model="form.rating"
                :options="[{text:$t('general.yes'),value:1},{text:$t('general.no'),value:0}]"
              />
            </div>
          </b-col>
          <b-col md="6" />
          <b-col
            md="6"
            class="mb-1"
          >
            <div class="d-flex justify-content-between mb-1 pl-1">
              <div>
                {{ $t('business.theme.rating-show') }}
              </div>
              <b-form-radio-group
                v-model="form.rating_result"
                :options="[{text:$t('general.yes'),value:1},{text:$t('general.no'),value:0}]"
              />
            </div>
          </b-col>
          <b-col md="6" />
        </b-row>
        <hr
          :data-content="$t('business.theme.desktop')"
          class="hr-text"
        >
        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <div class="d-flex justify-content-between mb-1 pl-1">
              <div>
                {{ $t('business.theme.desktop') }}
              </div>
              <b-form-radio-group
                v-model="form.desktop_type_color"
                :options="[{text:$t('business.theme.color'),value:1},{text:$t('business.theme.image'),value:0}]"
              />
            </div>
          </b-col>
          <b-col md="6" />
          <b-col
            v-if="form.desktop_type_color === 1"
            md="6"
          >
            <b-row class="d-flex justify-content-between align-items-center mb-1 mx-2">
              <b-col
                sm="6"
              >
                {{ $t('business.theme.image-background') }}
              </b-col>
              <b-col sm="6">
                <ok-color-picker v-model="form.desktop_color" />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-else
            md="12"
          >
            <my-menu-media-input
              ref="media"
              v-model="form.desktop_image"
              disable-video
              max-files="1"
              single
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <webapp-preview
          ref="preview"
          :loading="previewing"
          :previewed="previewed"
          @refresh="submit()"
        />
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormRadioGroup, BFormGroup,
} from 'bootstrap-vue'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import { mapGetters } from 'vuex'
import OkColorPicker from '@/@bya3/components/inputs/OkColorPicker'
import OkTooltip from '@/@bya3/components/utils/OkTooltip'
import OkSwitch from '@/@bya3/components/inputs/OkSwitch'
import LogoFileInput from '@/@bya3/components/inputs/LogoFileInput'
import WebappPreview from '@/views/theme/components/WebappPreview'
import MyMenuMediaInput from '@/views/media/MyMenuMediaInput'

export default {
  name: 'ThemeForm',
  components: {
    MyMenuMediaInput,
    WebappPreview,
    LogoFileInput,
    OkSwitch,
    OkTooltip,
    OkColorPicker,
    OkTextInput,
    ValidationObserver,
    BRow,
    BFormGroup,
    BCol,
    BFormRadioGroup,
  },
  data() {
    return {
      form: {},
      previewing: false,
      previewed: true,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.previewed = false
      },
    },
  },
  mounted() {
    setTimeout(() => this.init(), 300)
  },
  methods: {
    init() {
      this.form = {
        primary_color: this.user?.primary_color ?? '#fff',
        cate_wise: this.user?.theme?.cate_wise ?? 1,
        show_item_image: this.user?.theme?.show_item_image ?? 1,
        show_cat_image: this.user?.theme?.show_cat_image ?? 1,
        cat_shape: this.user?.theme?.cat_shape ?? 'rect',
        rating: this.user?.theme?.rating ?? 0,
        rating_result: this.user?.theme?.rating_result ?? 0,
        aspect_ratio: this.user?.theme?.aspect_ratio ?? 0,
        desktop_type_color: this.user?.theme?.desktop_type_color ?? 0,
        desktop_color: this.user?.theme?.desktop_color ?? '#ECECEC',
        aspect_ratio_color: this.user?.theme?.aspect_ratio_color ?? '#47B5FF',
        header_images: (this.user?.images ?? []).filter(img => img.pivot_type === 'theme_header'),
        desktop_image: (this.user?.images ?? []).filter(img => img.pivot_type === 'desktop'),
        logo: this.user?.logo ?? '',
        show_prices: this.user?.show_prices ?? 0,
        allow_order: this.user?.allow_order ?? 0,
      }
      this.previewed = true
    },
    prepareForSubmit() {
      const keys = ['cate_wise', 'show_item_image', 'show_cat_image', 'cat_shape', 'rating', 'rating_result',
        'aspect_ratio', 'desktop_type_color', 'desktop_color', 'aspect_ratio_color']
      const payload = {
        theme: pick(this.form, keys),
        primary_color: this.form.primary_color,
        logo: this.form.logo,
        desktop_image: this.form?.desktop_image[0]?.id ?? '',
        show_prices: this.form.show_prices,
        allow_order: this.form.allow_order,
      }
      if (this.form.header_images) {
        payload.header_images = this.form.header_images.filter(file => file.id).map(file => file.id)
      }
      if (!this.form.logo || typeof this.form.logo === 'string') {
        delete payload.logo
      }
      return payload
    },
    async isValid() {
      return this.$refs.form.validate()
    },
    submit() {
      this.previewing = true
      this.$emit('submit-review')
    },
    reloadPreview() {
      this.$refs.preview.reload()
      this.previewed = true
      this.previewing = false
    },
  },
}
</script>
