<template>
  <div
    class="mnassah-dropzone-input cursor-pointer w-100"
    :class="{uploading: uploading, inline: inlineTemplate}"
    @click.prevent="$emit('click')"
  >
    <div class="w-100">
      <vue-dropzone
        id="mnassah-dropzone"
        ref="dropzone"
        class="h-100"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        @vdropzone-sending="sendingEvent"
        @vdropzone-file-added="handleFileAdded"
        @vdropzone-complete="handleComplete"
        @vdropzone-removed-file="handleFileRemove"
        @vdropzone-error="handleError"
      >
        <div class="dropzone-custom-content">
          <feather-icon
            icon="ImageIcon"
            size="30"
          />
          <div>
            {{ $t('cloud.drag-or-click-image') }}
          </div>
        </div>
      </vue-dropzone>
    </div>
    <div
      v-if="uploading"
      class="position-absolute"
    >
      <b-spinner />
    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import { BSpinner } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { API_URL } from '@/constants/config'
import { videoMimes } from '@/constants/media/video-extensions'
import { dropzoneMyMenuTemplate } from '@/@bya3/components/inputs/file-inputs/dropzone-templates'
import ErrorHandler from '@/utils/error-handler'

export default {
  name: 'MyMenuDropzoneInput',
  components: {
    vueDropzone,
    BSpinner,
  },
  props: {
    value: {
      type: [String, Object, Number],
      default: '',
    },
    onlyImages: {
      type: Boolean,
      default: false,
    },
    inlineTemplate: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 50,
    },
  },
  data() {
    const imageTypes = 'image/jpg, image/png, image/jpeg'
    const videoTypes = videoMimes.join(',')

    return {
      uploadProgress: 0,
      uploading: false,
      videoAlreadySelected: false,
      upgradeModal: false,
      dropzoneOptions: {
        url: `${API_URL}/cloud/add`,
        thumbnailHeight: 100,
        headers: { Authorization: `Bearer ${this.$store.getters['auth/token']}` },
        acceptedFiles: imageTypes + (!this.onlyImages ? `, ${videoTypes}` : ''),
        autoProcessQueue: true,
        previewTemplate: dropzoneMyMenuTemplate(),
        maxFilesize: 100,
        resizeWidth: 1200,
        maxFiles: this.maxFiles,
        addRemoveLinks: false,
        clickable: false,
        accept: (file, done) => {
          if (this.totalStorage <= 0) {
            return done()
          }

          const FileSizeInMB = file.size / 1000000
          if (FileSizeInMB > this.availableStorage) {
            if (this.$refs.dropzone?.removeFile) this.$refs.dropzone.removeFile(file)
            this.upgradeModal = true
            return false
          }

          this.availableStorage -= FileSizeInMB
          return done()
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      totalStorage: 'menu/totalStorage',
    }),
    availableStorage: {
      get() { return this.$store.getters['menu/availableStorage'] },
      set(val) { if (this.totalStorage > 0) { this.$store.commit('menu/SET_STORAGE', { availableStorage: val }) } },
    },
  },
  watch: {
    onlyImages() {
      const imageTypes = 'image/jpg, image/png, image/jpeg'
      const videoTypes = videoMimes.join(',')
      this.$refs.dropzone.setOption('acceptedFiles', imageTypes + (!this.onlyImages ? `, ${videoTypes}` : ''))
    },
  },
  methods: {
    sendingEvent(file, xhr, formData) {
      formData.append('size', file.size)
      formData.append('type', file.type)
      if (file.thumbnail) {
        formData.append('thumbnail', file.thumbnail, file.thumbnail.name)
      }
    },
    handleComplete(response) {
      if (response.status !== 'success') {
        this.availableStorage += response.size / 1000000
        return
      }

      const parsedResponse = JSON.parse(response?.xhr?.response ?? '{}')

      const file = {
        id: parsedResponse?.ids?.[0],
        thumbnail: parsedResponse?.urls?.[0]?.thumbnail,
        original: {
          height: response.height,
          width: response.width,
          url: parsedResponse?.urls?.[0]?.url ?? response.dataURL,
        },
        med: {
          height: response.height,
          width: response.width,
          url: parsedResponse?.urls?.[0]?.url ?? response.dataURL,
        },
      }
      this.$emit('upload', file)
      setTimeout(() => {
        if (this.$refs.dropzone?.removeFile) this.$refs.dropzone.removeFile(response)
      }, 200)
    },
    addFileManually(file) {
      try {
        this.$refs.dropzone.addFile(file)
      } catch (e) {
        this.$notifyError(e)
      }
    },
    handleFileRemove(file) {
      if (file.status !== 'success') {
        this.availableStorage += file.size / 1000000
      }

      const isVideo = videoMimes.join(',').includes(file?.type?.toLowerCase())
      if (isVideo) {
        this.videoAlreadySelected = false
      }
    },
    handleFileAdded(file) {
      if (file.manuallyAdded) {
        return
      }
      const files = this.$refs.dropzone?.getAcceptedFiles ? this.$refs.dropzone.getAcceptedFiles() : []
      if (this.maxFiles === files.length && files.length >= 1) {
        if (this.$refs.dropzone?.removeFile) this.$refs.dropzone.removeFile(file)
      }

      const isVideo = videoMimes.join(',').includes(file?.type?.toLowerCase())
      if (isVideo) {
        if (this.videoAlreadySelected) {
          if (this.$refs.dropzone?.removeFile) this.$refs.dropzone.removeFile(file)
        } else {
          this.videoAlreadySelected = true
        }
      }
    },
    handleError(file, message) {
      this.$notifyError(message)
      const error = ErrorHandler.getErrorDetails(message)?.title || message
      const newNode = document.createElement('span')
      newNode.innerText = error

      const element = file.previewTemplate?.getElementsByClassName('dz-error-message-custom')?.[0]
      if (element) {
        element.appendChild(newNode)
      } else {
        file.previewTemplate.appendChild(newNode)
      }
    },
  },
}
</script>

<style lang="scss">
.mnassah-dropzone-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px 10px!important;

  &.uploading {
    .vue-dropzone {
      opacity: 0.5;
    }
  }

  .vue-dropzone {
    max-height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    border: 1px dashed #aaa8b2!important;
    background-color: #f0eff5!important;

    border-radius: 5px!important;

    width: 100%;
    overflow: auto;
    padding: 5px!important;

    .dz-preview {
      margin: 10px;
    }

    & > .dz-preview .dz-details {
      background-color: rgba(#5e5773, 0.6);
    }
  }

  .dark-layout & {
    .vue-dropzone {
      border: 1px dashed var(--theme-dark-input-border-color) !important;
      background-color: var(--theme-dark-input-bg) !important;
    }

    .img-thumbnail {
      background-color: var(--theme-dark-body-bg);
    }
  }

  .dz-image-preview {
    max-width: 100%;
  }

  .dz-file-preview-mnassah {
    background-color: var(--white);
    margin: 0 7px 10px 0!important;
    max-height: 90px!important;
    min-height: 10px!important;
    border-radius: 5px;
    padding: 5px 2px;
    position: relative;

    .preview-container {
      display: flex;
      align-items: center;

      img {
        width: 80px;
        height: 80px;
        min-width: 50px;
        min-height: 50px;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .mnassah-dropzone-input.inline &  {
      width: 100%;
      max-height: 90px!important;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .dz-file-details {
      padding-left: 3px;
      .dz-error-message-custom {
        font-size: 11px;
      }

      .dz-filename {
        font-size: 11px!important;
        max-width: 50px!important;
        max-height: 13px;
        text-overflow: ellipsis!important;
        overflow: hidden!important;
      }

      .dz-size {
        font-size: 12px;
      }
    }

    .dz-progress {
      margin-top: 0!important;
      border-radius: 4px;
      height: 8px;
    }

    .remove {
      padding: 2px;
      position: absolute;
      top: -7px;
      right: -7px;
      background-color: var(--danger);
      border-radius: 5px;
    }
  }
}
</style>
