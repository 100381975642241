<template>
  <div class="add-youtube-link">
    <validation-observer ref="form">
      <ok-text-input
        v-model="url"
        :label="$t('cloud.youtube-url')"
        :placeholder="$t('cloud.url')"
        is-required
        :rules="{regex: regex}"
      />

      <div class="youtube-url-description">
        {{ $t('cloud.youtube-url-description') }}
      </div>

    </validation-observer>

    <div class="d-flex justify-content-between align-items-center mt-1">
      <b-button
        variant="link"
        size="sm"
        @click="$emit('cancel')"
      >
        {{ $t('forms.back') }}
      </b-button>
      <b-button
        variant="dark"
        @click="submit"
      >
        {{ $t('forms.save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'

export default {
  name: 'AddYoutubeLink',
  components: { OkTextInput, BButton, ValidationObserver },
  data() {
    return {
      url: '',
      regex: '',
    }
  },
  mounted() {
    /* eslint-disable no-useless-escape */
    const youtubeUrls = [
      'https:\\/\\/www\.youtube\.com\\/watch?v=.*',
      'https:\\/\\/youtube\.com\\/shorts\\/.*',
      'https:\\/\\/youtu\.be\\/.*',
      'https:\\/\\/www\.youtube\.com\\/playlist?list=.*',
      'https:\\/\\/www\.youtube\.com\\/user\\/.*',
      'https:\\/\\/www\.youtube\.com\\/channel\\/.*',
      'https:\\/\\/www\.youtube\.com\\/c\\/.*',
      'https:\\/\\/www\.youtube\.com\\/.*',
    ]
    /* eslint-enable no-useless-escape */
    this.regex = new RegExp(youtubeUrls.join('|'))
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) return false
      this.$emit('save', this.url)
      return true
    },
  },
}
</script>

<style lang="scss">
.add-youtube-link {
  legend {
    font-size: 14px;

  }
}
</style>
