<template>
  <div class="preview-uploaded-video">
    <ok-video-player
      video-id="preview-uploaded-video-element"
      :src="previewVideo"
    />

    <div class="thumbnail-label">
      {{ $t('cloud.thumbnail') }}
    </div>
    <b-row class="no-gutters thumbnails-list">
      <b-col
        v-for="(thumbnail, idx) in thumbnails"
        :key="idx"
        cols="4"
        :class="{active: selectedIdx === idx}"
      >
        <img
          :src="thumbnail.base64"
          class="img-fluid"
          alt="thumbnail"
          @click="handleSelectThumbnail(thumbnail, idx)"
        >
        <feather-icon
          v-if="selectedIdx === idx"
          icon="CheckCircleIcon"
          class="selected-thumbnail-icon"
        />
      </b-col>
    </b-row>

    <div class="d-flex justify-content-between align-items-center mt-1">
      <b-button
        variant="link"
        size="sm"
        @click="$emit('cancel')"
      >
        {{ $t('forms.back') }}
      </b-button>
      <b-button
        variant="dark"
        @click="handleSave"
      >
        {{ $t('forms.save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BCol, BRow } from 'bootstrap-vue'
import OkVideoPlayer from '@/@bya3/components/media/OkVidePlayer'
import VideoThumbnailService from '@/services/video-thumbnail-service'

export default {
  name: 'PreviewUploadedVideo',
  components: {
    OkVideoPlayer, BButton, BCol, BRow,
  },
  props: {
    value: {
      type: [Blob, File, Object],
      default: () => ({}),
    },
  },
  data() {
    return {
      thumbnails: [],
      selectedIdx: 0,
      element: null,
    }
  },
  computed: {
    previewVideo() {
      return this.value ? URL.createObjectURL(this.value) : null
    },
  },
  mounted() {
    const el = document.getElementById('preview-uploaded-video-element')
    this.element = el
    el.addEventListener('loadedmetadata', this.generateThumbnails)
  },
  methods: {
    async generateThumbnails() {
      const el = this.element
      const { duration } = el
      console.log(duration)
      const chunk = duration / 3
      let prevTime = 0

      for (let i = 1; i <= 3; i += 1) {
        el.currentTime = prevTime + chunk / 2
        // eslint-disable-next-line no-await-in-loop
        await (new Promise(resolve => { el.addEventListener('timeupdate', function handler() { this.removeEventListener('timeupdate', handler); resolve() }) }))
        this.thumbnails.push({ ...VideoThumbnailService.capture(el), time: el.currentTime })
        prevTime += chunk
      }

      el.currentTime = 0
    },
    handleSelectThumbnail(thumbnail, idx) {
      this.selectedIdx = idx

      if (this.element && thumbnail?.time) {
        this.element.currentTime = thumbnail?.time
      }
    },
    handleSave() {
      this.$emit('save', this.thumbnails?.[this.selectedIdx])
    },
  },
}
</script>

<style lang="scss">
.preview-uploaded-video {
  .thumbnail-label {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--purple);
  }

  .thumbnails-list {
    .col-4 {
      padding: 4px;
      margin-bottom: 4px;
      position: relative;

      &.active {
        img {
          filter: brightness(50%);
          border: 2px solid var(--okmenu-color);
        }

        .selected-thumbnail-icon {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          width: 30px;
          height: 30px;
          color: #ffffff;
          z-index: 1;
        }
      }

      img {
        border-radius: 5px;
        cursor: pointer;
        height: 60px;
        object-fit: cover;
      }
    }
  }
}
</style>
